import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Homepage from '../HomePage'
import AboutPage from '../AboutPage/AboutUs';
import ErrorPage from '../ErrorPage/ErrorPage';
import ContactPage from '../ContactPage/ContactPage';
import AdminDashboard from '../AdminDashboard';
import PhotosGallery from '../PhotoGallery';
import EventPage from '../Events';
import EventDetailsPage from '../Events/details';


const AllRoute = () => {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Homepage />} />
          <Route path='home' element={<Homepage />} />
          <Route path='about' element={<AboutPage />} />
          <Route path='gallery' element={<PhotosGallery />} />
          <Route path='events' element={<EventPage />} />
          <Route path='events/:id' element={<EventDetailsPage />} />
          <Route path='*' element={<ErrorPage />} />
          <Route path='contact' element={<ContactPage />} />
          <Route path='admin-dashboard-secret' element={<AdminDashboard />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default AllRoute;
