import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar';
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import Logo from '../../images/logo-big.png'
import { Helmet } from "react-helmet";
import CustomTabs from '../../components/CustomTabs';

const AdminPanel = (props) => {
    const tabs = [
        {
            key: 1,
            name: "Events"
        },
        {
            key: 2,
            name: "Social Links"
        }
    ]
    return (
        <Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Admin Panel</title>
                <link rel="canonical" href="/about" />
                <meta name='description' content='' />
                <meta property="og:title" content="Admin Panel" />
                <meta property="og:description" content="" />
                <meta name="twitter:title" content="Admin Panel" />
                <meta name="twitter:description" content="" />
            </Helmet>
            <Navbar hclass={'wpo-header-style-3'} Logo={Logo} />
            <br />
            <h2 className='pd-10'>Welcome to Admin Panel</h2>
            <section className="wpo-about-section section-padding">
                <div className="wpo-about-section-wrapper">
                    <div className="row text-align-justify pt-20 pb-10">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <CustomTabs tabList={tabs} />
                        </div>
                    </div>
                </div>
            </section >
            <Footer />
            <Scrollbar />
        </Fragment>
    );
};

export default AdminPanel;