import React, { Fragment } from 'react';
import { memberData } from './members';

const CommitteeSection = () => {
    React.useEffect(() => {
    }, []);

    return (
        <Fragment>
            <div class="row text-align-justify pd-20">
                <div className="about-title pb-20">
                    <h2>Present Committee Members (2023-24)</h2>
                </div>
                {memberData.map((member) => (
                    <div class="col-lg-4 col-md-6 col-sm-12 col-12 pd-5 text-align-center">
                        <div class="card flex-display align-items-center border-none">
                            <img src={member.img} alt={member.name} style={{ height: '125px' }} />
                            <div class="container pt-2">
                                <h2>{member.name}</h2>
                                <p class="title">{member.role}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </Fragment>
    )
};

export default CommitteeSection;