import React, { useEffect, useState } from 'react';
import LoginPage from '../LoginPage';
import AdminPanel from './adminPanel';


const AdminDashboard = (props) => {
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        // Check if the user is already logged in
        const token = localStorage.getItem('token');
        const admin = localStorage.getItem('isAdmin');
        if (token && token !== 'undefined' && token !== undefined && admin) {
            setIsAdmin(true);
        }
    }, []);

    return (
        <>
            {isAdmin ? (
                <AdminPanel />
            ) : (
                <LoginPage />
            )}
        </>
    );
};

export default AdminDashboard;