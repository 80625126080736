import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { signOut } from '../../store/actions/authActions';
import { Link, useNavigate } from 'react-router-dom';

const ProfileDropdown = (props) => {
    const push = useNavigate();
    const dispatch = useDispatch();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const closeDropdown = () => {
        setIsDropdownOpen(false);
    };

    const logOut = () => {
        dispatch(signOut(push));
        props.setLoggedIn(false)
    }

    // Handle logout or other actions when an option is clicked
    const handleOptionClick = (option) => {
        if (option === 'logout') {
            // Perform logout logic here
            console.log('Logout clicked');
        }
        // Close the dropdown
        closeDropdown();
    };

    useEffect(() => {
        // Check if the user is already logged in
        const token = localStorage.getItem('token');
        const admin = localStorage.getItem('isAdmin');
        if (token && token !== 'undefined' && token !== undefined && admin) {
            setIsAdmin(true);
        }
    }, []);

    return (
        <div className="profile-dropdown" onBlur={closeDropdown} tabIndex="0">
            <div className="profile-icon" onClick={toggleDropdown}>
                <i className="fa fa-user-circle fa-2x" aria-hidden="true"></i>
            </div>
            {/* {isDropdownOpen && ( */}
            <div className="dropdown-content">
                <ul>
                    {/* <li><Link to="/about">Profile</Link></li> */}
                    <li onClick={logOut}>Logout</li>
                </ul>
            </div>
            {/* )} */}
        </div>
    );
}

export default ProfileDropdown;
