import React, { useEffect, useState, Fragment } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    TextField,
    Button,
    Grid
} from "@mui/material";
import { Edit} from "@mui/icons-material";
import axios from 'axios';
import CustomLoader from '../../components/CustomLoader';

const AdminSocialPage = () => {
    const [data, setData] = useState({});
    const [editKey, setEditKey] = useState(null);
    const [editedValue, setEditedValue] = useState("");
    const [loading, setLoading] = useState(false);

    const fetchSocials = async () => {
        try {
            setLoading(true);
            const response = await axios.get(process.env.REACT_APP_API_BASE_URL + '/socials');
            const { _id, updatedAt, ...socialLinks } = response.data[0];
            setData(socialLinks);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error("Error fetching data:", error);
        }
    };

    const handleEdit = (key, value) => {
        setEditKey(key);
        setEditedValue(value);
    };

    // Handle saving
    const handleSave = async (key) => {
        try {
            setLoading(true);
            const updatedData = { ...data, [key]: editedValue };
            await axios.put(process.env.REACT_APP_API_BASE_URL + "/socials/update", { [key]: editedValue });
            setData(updatedData);
            setEditKey(null);
            setEditedValue("");
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error("Error updating data:", error);
        }
    };

    useEffect(() => {
        fetchSocials();
    }, []);

    return (
        <Fragment>
            <CustomLoader show={loading} />
            <section className="min-height-500">
                <Grid container alignItems="center" justifyContent="space-between" sx={{ mb: 3 }}>
                    <Grid item xs={12} sx={{ mb: 2 }} sm="auto">
                        <h1>Social Links</h1>
                    </Grid>
                </Grid>
                <TableContainer component={Paper} sx={{ maxWidth: "auto", margin: "auto", mt: 4 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell><strong>Platform</strong></TableCell>
                                <TableCell><strong>Link</strong></TableCell>
                                <TableCell><strong>Actions</strong></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.entries(data).map(([key, value]) => (
                                <TableRow key={key}>
                                    <TableCell>{key.charAt(0).toUpperCase() + key.slice(1)}</TableCell>
                                    <TableCell>
                                        {editKey === key ? (
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                size="small"
                                                value={editedValue}
                                                onChange={(e) => setEditedValue(e.target.value)}
                                            />
                                        ) : (
                                            value || "No Link"
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {editKey === key ? (
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                onClick={() => handleSave(key)}
                                            >
                                                Save
                                            </Button>
                                        ) : (
                                            <IconButton color="primary" onClick={() => handleEdit(key, value)}>
                                                <Edit />
                                            </IconButton>
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </section >
        </Fragment>
    );
};

export default AdminSocialPage;
