import React, { useEffect, useState, Fragment } from 'react';
import EventForm from './eventForm';
import { Card, CardMedia, CardContent, Typography, Button, Grid } from '@mui/material';
import axios from 'axios';
import CustomPopup from '../../components/CustomPopup';
import CustomLoader from '../../components/CustomLoader';
import { formatDate } from '../../utils';

const AdminEventPage = () => {
    const [addEvent, setAddEvent] = useState(false);
    const [eventId, setEventId] = useState("");
    const [events, setEvents] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [deleteEventId, setdeleteEventId] = useState("")
    const [loading, setLoading] = useState(false);

    const handleBackClick = () => {
        setAddEvent(false)
    };

    const addNewEvent = () => {
        setEventId("")
        setdeleteEventId("")
        setAddEvent(true);
    }

    const handleEdit = (eventId) => {
        setEventId(eventId);
        setAddEvent(true);
    };

    const openPopup = (eventId) => {
        setShowPopup(true);
        setdeleteEventId(eventId)
    }

    const handleDelete = async () => {
        setLoading(true);
        const response = await axios.delete(process.env.REACT_APP_API_BASE_URL + '/events/' + deleteEventId);
        if (response.status === 200) {
            setEvents(events.filter(event => event._id !== deleteEventId));
        }
        setLoading(false);
        setShowPopup(false);
        setdeleteEventId("")
    };

    const handleClose = () => {
        setShowPopup(false);
        setdeleteEventId("")
    }

    useEffect(() => {
        setdeleteEventId("")
        setLoading(true);
        axios.get(process.env.REACT_APP_API_BASE_URL + '/events').then((response) => {
            setEvents(response.data);
            setLoading(false);
        });
    }, [addEvent]);

    return (
        <Fragment>
            <CustomLoader show={loading} />
            <CustomPopup msg="Are you sure you want to remove this event ?" open={showPopup} save={handleDelete} close={handleClose} showButtons={true} />
            <section className="min-height-500">
                {addEvent ? <>
                    <div onClick={handleBackClick} className='backButton pb-10'>
                        <i className="fa fa-arrow-left icon"></i>
                        <span className='backText'>Back</span>
                    </div>
                    <EventForm eventId={eventId} setAddEvent={setAddEvent} />
                </> : <div>
                    <Grid container alignItems="center" justifyContent="space-between" sx={{ mb: 3 }}>
                        <Grid item xs={12} sx={{ mb: 2 }} sm="auto">
                            <h1>Events List</h1>
                        </Grid>
                        <Grid item xs={12} sm="auto">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={addNewEvent}
                                fullWidth
                            >
                                Add Event
                            </Button>
                        </Grid>
                    </Grid>
                    <br />
                    <Grid container spacing={2}>
                        {events?.length > 0 ? events.map((event) => (
                            <Grid item xs={12} sm={6} md={4} key={event._id}>
                                <Card sx={{ maxWidth: 345 }}>
                                    <CardMedia
                                        component="img"
                                        height="140"
                                        image={process.env.REACT_APP_API_BASE_URL + '/events/image/' + event.image.split("=")[event.image.split("=").length - 1]}
                                        alt={event.name}
                                    />
                                    <CardContent>
                                        <Typography variant="h5" component="div">
                                            {event.name}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {event.shortDescription}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            Venue: {event.venue}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            Date: {formatDate(event.eventDate)}
                                        </Typography>
                                        <div style={{ marginTop: '16px' }}>
                                            <Button variant="contained" color="primary" onClick={() => handleEdit(event._id)}>
                                                Edit
                                            </Button>
                                            <Button variant="outlined" color="secondary" onClick={() => openPopup(event._id)} style={{ marginLeft: '8px' }}>
                                                Delete
                                            </Button>
                                        </div>
                                    </CardContent>
                                </Card>
                            </Grid>
                        )) : <h4 className='pd-25'>No Events</h4>}
                    </Grid>
                </div>}
            </section >
        </Fragment>
    );
};

export default AdminEventPage;
