import React, { Fragment, useState, Suspense, lazy } from 'react';
import { Helmet } from "react-helmet";
import Footer from '../../components/footer';
import Hero from '../../components/hero';
import Navbar from '../../components/Navbar';
import Scrollbar from '../../components/scrollbar';
import Logo from '../../images/logo-big.png';
import CustomLoader from '../../components/CustomLoader';
import CustomPopup from '../../components/CustomPopup';
import { Card, CardContent, Typography, CardMedia, Grid } from '@mui/material';
import img1 from '../../images/community.jpg';
import img2 from '../../images/cultural.webp';
import img3 from '../../images/unity.jpg';
const Highlights = lazy(() => import('../Highlights'));

const HomePage = () => {
    const [showPopup, setShowPopup] = useState(false);
    const [loading, setLoading] = useState(true);

    const handleClose = () => {
        setShowPopup(false);
    }

    React.useEffect(() => {
        window.scroll(0, 0);
        setTimeout(() => {
            setLoading(false);
        }, 200);
        if (!localStorage.getItem('welcome')) {
            setShowPopup(true);
            localStorage.setItem('welcome', true)
        }
    }, []);

    return (
        <Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Home</title>
                <link rel="canonical" href="https://gsi.ie/" />
                <meta name='description' content='Gujarati Samaj Ireland' />
                <meta property="og:title" content="Home" />
                <meta property="og:description" content="Gujarati Samaj Ireland" />
                <meta name="twitter:title" content="Home" />
                <meta name="twitter:description" content="Gujarati Samaj Ireland" />
            </Helmet>
            <CustomLoader show={loading} />
            <Navbar topbarBlock={'wpo-header-style-3'} hclass={'wpo-header-style-3'} Logo={Logo} />
            <Hero />
            <CustomPopup open={showPopup} msg="ગુજરાતી સમાજ આયર્લેન્ડ મા આપનું સ્વાગત છે" close={handleClose} showHand={true} addClass='f-s-20 font-bold' />
            <section className="wpo-about-section section-padding">
                <div className="wpo-about-section-wrapper">
                    <div className="row text-align-justify pt-10 pb-10">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="wpo-about-content">
                                <div className="wpo-about-content-inner">
                                    <p className='f-s-18 text-align-center'><i>Welcome to the official website of Gujarati Samaj Ireland (GSI)!</i></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row text-align-justify pt-10 pb-10">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="wpo-about-content">
                                <div className="wpo-about-content-inner">
                                    <p className='f-s-18'>Our main goal is to honour and cherish our diverse Gujarati culture and heritage.
                                        We achieve this by hosting a variety of cultural, educational, and recreational events that involve our members and add vibrancy to Ireland's multicultural landscape.</p>
                                    <p className='f-s-18'>We're proud to organize numerous cultural activities such as Holi, Janmashtami, Navratri, Diwali, and more.
                                        These events showcase our dedication for preserving Gujarati culture in Ireland.</p>
                                    <p className='f-s-18'>Join us as we continue to cherish and celebrate our rich heritage here in Ireland!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row text-align-justify pt-20 pb-10 background-light-grey">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="wpo-about-content">
                                <div className="about-title">
                                    <h2>Our Vision</h2>
                                </div>
                                <div className="wpo-about-content-inner">
                                    <p className='f-s-18'>Our vision is to preserve and uphold the rich cultural and linguistic legacy of the Gujarati heritage through embracing diversity,
                                        fostering a strong sense of belonging for the generations to come.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row text-align-justify pt-20 pb-10">
                        <div className="wpo-about-content">
                            <div className="about-title">
                                <h2>Our Mission</h2>
                            </div>
                            <div className="wpo-about-content-inner">
                                <p className='f-s-18'>Our mission is to nurture strong community ties and unity while preserving and celebrating
                                    Gujarati heritage through a diverse range of cultural, educational, and recreational activities in Ireland.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row text-align-justify pt-20 pb-10 background-light-grey">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="wpo-about-content">
                                <div className="about-title">
                                    <h2>Our Values</h2>
                                </div>
                                <div className="wpo-about-content-inner">
                                    <Grid container spacing={3} justifyContent="center">
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Card sx={{ minWidth: 200, margin: 2, boxShadow: 3, textAlign: 'center', justifyContent: 'center' }}>
                                                <CardMedia
                                                    component="img"
                                                    height="140"
                                                    image={img1}
                                                    alt="Card image"
                                                    maxWidth="80%"
                                                    sx={{ objectFit: 'contain' }}
                                                />
                                                <CardContent>
                                                    <Typography variant="h5" color="text.secondary" sx={{ fontWeight: 'bold' }}>
                                                        Community
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Card sx={{ minWidth: 200, margin: 2, boxShadow: 3, textAlign: 'center', justifyContent: 'center' }}>
                                                <CardMedia
                                                    component="img"
                                                    height="140"
                                                    image={img3}
                                                    alt="Card image"
                                                    maxWidth="80%"
                                                    sx={{ objectFit: 'contain' }}
                                                />
                                                <CardContent>
                                                    <Typography variant="h5" color="text.secondary" sx={{ fontWeight: 'bold' }}>
                                                        Unity
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Card sx={{ minWidth: 200, margin: 2, boxShadow: 3, textAlign: 'center', justifyContent: 'center' }}>
                                                <CardMedia
                                                    component="img"
                                                    height="140"
                                                    image={img2}
                                                    alt="Card image"
                                                    maxWidth="80%"
                                                    sx={{ objectFit: 'contain' }}
                                                />
                                                <CardContent>
                                                    <Typography variant="h5" color="text.secondary" sx={{ fontWeight: 'bold' }}>
                                                        Cultural Enrichment
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                            </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Suspense fallback={<div></div>}>
                <Highlights />
            </Suspense>
            {/* <Suspense fallback={<div></div>}>
            </Suspense> */}
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};

export default HomePage;