import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FontAwesome from '../UiStyle/FontAwesome';
import Hands from '../../images/handspraying.jpg';

export default function CustomPopup(props) {
    return (
        <div>
            <Dialog
                open={props.open}
                onClose={props.close}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {/* {"Use Google's location service?"} */}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" className={' display-flex text-align-center ' + props.addClass}>
                        {props.showHand ? <img src={Hands} alt="" style={{ height: '25px' }} /> : null}
                        {props.msg}
                        {props.showHand ? <img src={Hands} alt="" style={{ height: '25px' }} /> : null}
                    </DialogContentText>
                </DialogContent>
                {props.showButtons ? <DialogActions>
                    <Button onClick={props.close}>Disagree</Button>
                    <Button onClick={props.save} autoFocus>
                        Agree
                    </Button>
                </DialogActions> : null}
            </Dialog>
        </div>
    );
}