import React, { useEffect, useState, Fragment } from 'react';
import PageTitle from '../../components/pagetitle/PageTitle';
import Navbar from '../../components/Navbar';
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import Logo from '../../images/logo-big.png'
import { Helmet } from "react-helmet";
import CustomLoader from '../../components/CustomLoader';
import 'react-image-lightbox/style.css';
import Lightbox from 'react-image-lightbox';
import './style.scss';

const PhotosGallery = () => {
    const [folders, setFolders] = useState([]);
    const [photos, setPhotos] = useState([]);
    const [showFolder, setShowFolder] = useState(false);
    const [loader, setLoader] = useState(false);
    const [noText, setNoText] = useState("");
    const [selectedFolder, setSelectedFolder] = useState("");
    const [folderImages, setFolderImages] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);

    const fetchFolders = async () => {
        try {
            setNoText("")
            setShowFolder(true);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + '/photo');
            const data = await response.json();
            setFolders(data);
            if (data.length <= 0) {
                setNoText("No Images")
            }
            fetchFolderImages();
            setLoader(false);
        } catch (error) {
            console.error('An error occurred during fetch:', error);
            setNoText("Please, Try again. Sorry for the trouble.")
            setLoader(false);
        }
    }

    const fetchFolderImages = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + '/photo/folderImages');
            const data = await response.json();
            setFolderImages(data);
        } catch (error) {
            console.error('An error occurred during fetch:', error);
            setNoText("Please, Try again. Sorry for the trouble.")
            setLoader(false);
        }
    }

    useEffect(() => {
        setLoader(true);
        fetchFolders();
    }, []);


    const getImages = async (id, name) => {
        setNoText("")
        setPhotos([]);
        setSelectedFolder(name);
        setLoader(true);
        setShowFolder(false);
        const response = await fetch(process.env.REACT_APP_API_BASE_URL + '/photo/' + id + '/images');
        const data = await response.json();
        if (data.length <= 0) {
            setNoText("No Images")
        }
        setPhotos(data);
        setLoader(false);
    }

    const handleBackClick = () => {
        fetchFolders();
        fetchFolderImages();
    };


    return (
        <Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Gallery</title>
                <link rel="canonical" href="/gallery" />
                <meta name='description' content='' />
                <meta property="og:title" content="Gallery" />
                <meta property="og:description" content="" />
                <meta name="twitter:title" content="Gallery" />
                <meta name="twitter:description" content="" />
            </Helmet>
            <CustomLoader show={loader} />
            <Navbar hclass={'wpo-header-style-3'} Logo={Logo} />
            <PageTitle pageHeaderImage={'wpo-gallery-area'} pagesub={'Gallery'} />
            <section className="section-padding min-height-500">
                <div className="">
                    <div className="row text-align-justify pt-20 pb-10 gallery-container">
                        {!showFolder ? <div onClick={handleBackClick} className='backButton pb-10'>
                            <i className="fa fa-arrow-left icon"></i>
                            <span className='backText'>Back</span>
                        </div> : null}
                        {!showFolder ? <h2>{selectedFolder}</h2> : null}
                        {noText !== "" ? <h3 className='pd-20'>{noText}</h3> : null}
                        {showFolder ? folders.length > 0 && folders?.reverse().map((folder) => (
                            <div onClick={() => getImages(folder.id, folder.name)} key={folder.id} className='folderItem'>
                                <div className='iconContainer'>
                                    {/* <i className="fa fa-folder folderIcon" /> */}
                                    <img src={
                                        folderImages.filter((e) => e.name.split(".")[0] === folder.name)[0]?.thumbnailLink
                                    } alt={folder.name} />
                                </div>
                                <div className='folderName'>
                                    {folder.name}
                                </div>
                            </div>
                        )) : photos.length > 0 && (
                            <div className='imageContainer'>
                                {photos.map((image, index) => (
                                    <img
                                        className='image'
                                        key={image.id}
                                        src={image.thumbnailLink}
                                        alt={image.name}
                                        onClick={() => {
                                            setIsOpen(true);
                                            setPhotoIndex(index);
                                        }}
                                    />
                                ))}
                            </div>
                        )}
                        {isOpen && (
                            <Lightbox
                                mainSrc={photos[photoIndex]?.thumbnailLink}
                                nextSrc={photos[(photoIndex + 1) % photos.length]?.thumbnailLink}
                                prevSrc={photos[(photoIndex + photos.length - 1) % photos.length]?.thumbnailLink}
                                onCloseRequest={() => setIsOpen(false)}
                                onMovePrevRequest={() =>
                                    setPhotoIndex((photoIndex + photos.length - 1) % photos.length)
                                }
                                onMoveNextRequest={() =>
                                    setPhotoIndex((photoIndex + 1) % photos.length)
                                }
                            />
                        )}
                    </div>
                </div>
            </section >
            <Footer />
            <Scrollbar />
        </Fragment>
    );
};

export default PhotosGallery;
