import React, { Fragment, useEffect } from 'react';
import Navbar from '../../components/Navbar';
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import Logo from '../../images/logo-big.png'
import { Helmet } from "react-helmet";
import ContactpageSec from '../../components/ContactpageSec/ContactpageSec';


const ContactPage = () => {

    useEffect(() => {
        window.scroll(0, 0);
    });

    return (
        <Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Contact Us</title>
                <link rel="canonical" href="https://gsi.ie/contact" />
                <meta name='description' content='Your inquiries matter to us, and we are here to assist. Reach out for support, feedback, or collaboration. Our dedicated team is ready to respond and make your interaction seamless.' />
                <meta property="og:title" content="Contact Us" />
                <meta property="og:description" content="Your inquiries matter to us, and we are here to assist. Reach out for support, feedback, or collaboration. Our dedicated team is ready to respond and make your interaction seamless." />
                <meta name="twitter:title" content="Contact Us" />
                <meta name="twitter:description" content="Your inquiries matter to us, and we are here to assist. Reach out for support, feedback, or collaboration. Our dedicated team is ready to respond and make your interaction seamless." />
            </Helmet>
            <Navbar hclass={'wpo-header-style-3'} Logo={Logo} />
            {/* <PageTitle pageTitle={'Contact Us'} pagesub={'Contact'}/>  */}
            <ContactpageSec />
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};

export default ContactPage;

