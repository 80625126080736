import { combineReducers } from 'redux'
import authReducer from "./authReducer";
import contactReducer from './contactReducer';


const rootReducer = combineReducers({
    auth: authReducer,
    contactReducer: contactReducer
});

export default rootReducer;