import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../images/logo-big.png'
import axios from 'axios';
import CustomLoader from '../../components/CustomLoader';


const Footer = (props) => {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    const onInstaClickHandler = () => {
        window.open(data["instagram"] || "", "_blank", "noopener,noreferrer");
    }

    const onFbClickHandler = () => {
        window.open(data["facebook"] || "", "_blank", "noopener,noreferrer")
    }

    const onWhatsppClickHandler = () => {
        window.open(data["whatsapp"] || "", "_blank", "noopener,noreferrer")
    }

    const fetchSocials = async () => {
        try {
            setLoading(true);
            const response = await axios.get(process.env.REACT_APP_API_BASE_URL + '/socials');
            const { _id, updatedAt, ...socialLinks } = response.data[0];
            setData(socialLinks);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        fetchSocials();
    }, []);

    return (
        <footer className="wpo-site-footer">
            <CustomLoader show={loading} />
            <div className="wpo-upper-footer">
                <div className="container">
                    <div className="row">
                        <div className="col col-lg-3 col-md-3 col-sm-12 col-12">
                            <div className="widget about-widget">
                                <div className="logo widget-title">
                                    <img src={Logo} alt="footer" />
                                </div>
                                <ul>
                                    <li>
                                        <Link onClick={onFbClickHandler} to="/">
                                            <i className="ti-facebook"></i>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link onClick={onInstaClickHandler} to="/">
                                            <i className="ti-instagram"></i>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link onClick={onWhatsppClickHandler} to="/">
                                            <i className="fa fa-whatsapp fa-2xl"></i>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col col-lg-2 col-md-2 col-sm-0 col-0"></div>
                        <div className="col col-lg-3 col-md-3 col-sm-12 col-12">
                            <div className="widget quick-links">
                                <div className="widget-title">
                                    <h3>Quick Links</h3>
                                </div>
                                <ul className='f-s-18'>
                                    <li>
                                        <Link onClick={ClickHandler} to="/">
                                            Home
                                        </Link>
                                    </li>
                                    <li>
                                        <Link onClick={ClickHandler} to="/about">
                                            About Us
                                        </Link>
                                    </li>
                                    <li>
                                        <Link onClick={ClickHandler} to="/gallery">
                                            Gallery
                                        </Link>
                                    </li>
                                    <li>
                                        <Link onClick={ClickHandler} to="/events">
                                            Events
                                        </Link>
                                    </li>
                                    <li>
                                        <Link onClick={ClickHandler} to="/contact">
                                            Contact Us
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col col-lg-1 col-md-1 col-sm-0 col-0"></div>
                        <div className="col col-lg-3 col-md-3 col-sm-12 col-12">
                            <div className="widget wpo-service-link-widget">
                                <div className="widget-title">
                                    <h3>Contact Us</h3>
                                </div>
                                <div className="contact-ft f-s-18">
                                    <ul>
                                        <li><i className="fi flaticon-placeholder"></i>Co. Dublin</li>
                                        <li><i className="fi flaticon-send"></i><a href={`mailto:${data["gmail"] || ""}`}>{data["gmail"] || ""}</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="wpo-lower-footer">
                <div className="container">
                    <div className="row">
                        <div className="col col-xs-12">
                            <p className="copyright"> Copyright &copy; {new Date().getFullYear()} by <Link onClick={ClickHandler} to="/">GSI and IdeasPoint.</Link>.
                                All Rights Reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;