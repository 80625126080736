import React, { useEffect, useState, Fragment } from 'react';
import PageTitle from '../../components/pagetitle/PageTitle';
import Navbar from '../../components/Navbar';
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import Logo from '../../images/logo-big.png'
import { Helmet } from "react-helmet";
import CustomLoader from '../../components/CustomLoader';
import { Card, CardContent, CardMedia, Typography, Grid, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { formatDate } from '../../utils';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EventIcon from '@mui/icons-material/Event';

const EventPage = () => {
    const [eventList, setEventList] = useState([]);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const handleEventClick = (event) => {
        navigate(`/events/${event._id}`, { state: { event } });
    };

    useEffect(() => {
        setLoading(true);
        axios.get(process.env.REACT_APP_API_BASE_URL + '/events').then((response) => {
            setEventList(response.data);
            setLoading(false);
        });
    }, []);

    return (
        <Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Events</title>
                <link rel="canonical" href="/events" />
                <meta name='description' content='' />
                <meta property="og:title" content="Events" />
                <meta property="og:description" content="" />
                <meta name="twitter:title" content="Events" />
                <meta name="twitter:description" content="" />
            </Helmet>
            <CustomLoader show={loading} />
            <Navbar hclass={'wpo-header-style-3'} Logo={Logo} />
            <PageTitle pageHeaderImage={'wpo-events-area'} pagesub={'Events'} />
            <section className="section-padding min-height-500">
                <Box padding={4}>
                    <Grid container spacing={4}>
                        {eventList.length > 0 ? eventList.map((event) => (
                            <Grid item xs={12} sm={6} md={4} key={event._id}>
                                <Card>
                                    <CardMedia component="img" height="140"
                                        image={process.env.REACT_APP_API_BASE_URL + '/events/image/' + event.image.split("=")[event.image.split("=").length - 1]}
                                        alt={event.name} />
                                    <CardContent>
                                        <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} justifyContent="space-between" alignItems={{ xs: 'left', sm: "center" }} marginBottom={1}>
                                            <Typography variant="h5" className="event-title">{event.name}</Typography>
                                            <Box display="flex" alignItems="center">
                                                <EventIcon fontSize="small" sx={{ mr: 0.5 }} />
                                                <Typography variant="subtitle1" className="event-date">
                                                    {formatDate(event.eventDate)}
                                                </Typography>
                                            </Box>
                                        </Box>

                                        <Typography variant="body2" color="textSecondary" gutterBottom>
                                            {event.shortDescription}
                                        </Typography>

                                        <Box display="flex" alignItems="center" marginTop={2}>
                                            <LocationOnIcon fontSize="small" sx={{ mr: 0.5 }} />
                                            <Typography variant="subtitle1">
                                                Venue: {event.venue}
                                            </Typography>
                                        </Box>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size='small'
                                            fullWidth
                                            sx={{ mt: 3 }}
                                            onClick={() => handleEventClick(event)}>
                                            View Details
                                        </Button>
                                    </CardContent>
                                </Card>
                            </Grid>
                        )): <h3 className='pd-20'>No Events</h3>}
                    </Grid>
                </Box>
            </section >
            <Footer />
            <Scrollbar />
        </Fragment>
    );
};

export default EventPage;
