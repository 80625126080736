export const formatDate = (dateString) => {
    const date = new Date(dateString);
    date.setHours(date.getHours() + 1);
    const formattedDate = date.toLocaleDateString('en-GB');
    const formattedTime = date.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
    }); // HH:MM AM/PM format
    return `${formattedDate} ${formattedTime}`;
};

export const isValidUrl = (url) => {
    const pattern = new RegExp(
        '^(https?:\\/\\/)?.+\\..+',
        'i' // Case insensitive
    );
    return pattern.test(url);
};