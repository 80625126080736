import React, { Fragment, useState, useEffect } from 'react';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Collapse from "@mui/material/Collapse";
import { Link, useNavigate } from "react-router-dom";
import { signOut } from '../../store/actions/authActions';
import { useDispatch } from 'react-redux';
import './style.css';

const MobileMenu = ({ loggedIn, setLoggedIn }) => {
    const push = useNavigate();
    const dispatch = useDispatch();
    const [openId, setOpenId] = useState(0);
    const [menuActive, setMenuState] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);

    const logOut = () => {
        dispatch(signOut(push));
        setLoggedIn(false)
    }

    const menus = [
        {
            id: 1,
            title: 'Home',
            link: '/home'
        },
        {
            id: 2,
            title: 'About Us',
            link: '/about',
        },
        {
            id: 3,
            title: 'Gallery',
            link: '/gallery',
        },
        {
            id: 4,
            title: 'Events',
            link: '/events',
        },
        {
            id: 5,
            title: 'Contact Us',
            link: '/contact',
        },
        isAdmin ? {
            id: 6,
            title: 'Admin Panel',
            link: '/admin-dashboard-secret',
        } : null,
        isAdmin ? {
            id: 7,
            title: 'Logout',
            link: logOut,
        } : null
    ]

    useEffect(() => {
        // Check if the user is already logged in
        const token = localStorage.getItem('token');
        const admin = localStorage.getItem('isAdmin');
        if (token && token !== 'undefined' && token !== undefined && admin) {
            setIsAdmin(true);
        }
    }, []);

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <div>
            <div className={`mobileMenu ${menuActive ? "show" : ""}`}>
                <div className="menu-close">
                    <div className="clox" onClick={() => setMenuState(!menuActive)}><i className="ti-close"></i></div>
                </div>

                <ul className="responsivemenu">
                    {menus.map((item, mn) => {
                        return (
                            <ListItem className={item?.id === openId ? 'active' : null} key={mn}>
                                {item === null ? null : item.submenu ?
                                    <Fragment>
                                        <p onClick={() => setOpenId(item?.id === openId ? 0 : item?.id)}>{item?.title}
                                            <i className={item?.id === openId ? 'fa fa-angle-up' : 'fa fa-angle-down'}></i>
                                        </p>
                                        <Collapse in={item?.id === openId} timeout="auto" unmountOnExit>
                                            <List className="subMenu">
                                                <Fragment>
                                                    {item.submenu.map((submenu, i) => {
                                                        return (
                                                            <ListItem key={i}>
                                                                <Link onClick={ClickHandler} className="active"
                                                                    to={submenu.link}>{submenu.title}</Link>
                                                            </ListItem>
                                                        )
                                                    })}
                                                </Fragment>
                                            </List>
                                        </Collapse>
                                    </Fragment>
                                    : item?.title === 'Logout' ?
                                        <Link onClick={logOut}>Logout</Link> :
                                        <Link className='active'
                                            to={item.link}>{item.title}</Link>
                                }
                            </ListItem>
                        )
                    })}
                </ul>

            </div>

            <div className="showmenu" onClick={() => setMenuState(!menuActive)}>
                <button type="button" className="navbar-toggler open-btn">
                    <span className="icon-bar first-angle"></span>
                    <span className="icon-bar middle-angle"></span>
                    <span className="icon-bar last-angle"></span>
                </button>
            </div>
        </div>
    )
}

export default MobileMenu;